import React, { useState, useEffect } from "react"
import axios from "axios"
import { Link } from "gatsby"
import Helmet from "react-helmet"

import ReqCur from "../components/reqCur"

import { StripeProvider } from "react-stripe-elements"
import { Stripe } from "stripe.js"
import Checkout from "../components/checkout"
import Instalments from '../components/instalments.js'

import Contacts from "../components/contactform"
// import Covid from '../components/covid';

import pythonImage from "../../pics/programming_with_python_self_learning_course_in_barcelona_code_school.png"

const PythonSelfCourse = () => {


	return (
		<div className="flexContent">
		<Helmet
		title="Programming with Python / Self-learning video course"
		meta={[
			{
				name: "description",
				content:
				"Programming with Python self-learning video course is designed for complete beginners to introduce them to programming and teach Python. Learn programming from zero by following our curriculum with text materials, video lessons and exercises with tests to check your code solutions.",
			},
			{
				name: "keywords",
				content:
				"programming with python, python self-learning course, learn python online",
			},
			{
				name: "viewport",
				content: "width=device-width, initial-scale=1",
			},
			{
				property: "og:title",
				content: "Programming with Python course",
			},
			{
				property: "og:description",
				content:
				"Programming with Python self-learning video course is designed for complete beginners to introduce them to programming and teach Python. Learn programming from zero by following our curriculum with text materials, video lessons and exercises with tests to check your code solutions",
			},
			{
				property: "og:image",
				content:
				"https://barcelonacodeschool.com/files/pics/programming_with_python.png",
			},
			{
				property: "og:url",
				content:
				"https://barcelonacodeschool.com/programming-with-python/",
			},
			{ name: "twitter:card", content: "summary_large_image" },
			]}
			script={[{ src: "https://js.stripe.com/v3/" }]}
			/>
			<h1 className='transparentContainer flex900'>Programming with Python self-learning course</h1>


			<img
			style={{ width: "100%" }}
			className="banners flex1200 offsetImage"
			src={pythonImage}
			alt="Programming with Python self-learning course for beginners"
			/>

			<h2 className='transparentContainer flex450'>Self-learning course for beginners</h2>





			<div className='socialsharebuttons transparentContainer'>
			<div className='socialshare'>
			<a className="twitter-share-button "
			href="https://twitter.com/intent/tweet?text='Programming with Python in Barcelona Code School'&url=https://barcelonacodeschool.com/programming-with-python/"
			data-size="large">
			<button>tweet</button>
			</a>
			</div>

			<div className="fb-share-button socialshare" data-href="https://developers.facebook.com/docs/plugins/" data-layout="button" data-size="small">
			<a target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=https://barcelonacodeschool.com/programming-with-python/" className="fb-xfbml-parse-ignore">
			<button>facebook</button>
			</a>
			</div>
			</div>






			<h2 className="transparentContainer flex300">
			<span className="highlighter">Lifelong</span> access to the materials to study at your own pace!</h2>
			<h2 className="transparentContainer flex225">Curriculum includes text materials, video-lessons, exercises with tests to check if your code works</h2>


			<article className="transparentContainer flex450">
			<h2>Overview</h2>
			<div className='whiteCard'>
			<ul>
			<li>
			7 hours of <span className="highlighter">video lessons</span> in total
			</li>
			<li>
			More than 100 <span className="highlighter">coding challenges</span> to
			solve
			</li>
			<li>
			Learn at <span className="highlighter">your own pace</span>
			</li>
			<li>
			Suitable for complete <span className="highlighter">beginners</span>
			</li>
			</ul>
			</div>
			</article>

			<article className="transparentContainer flex450">
			<h2>What you'll learn</h2>
			<div className='whiteCard'>
			<ul>
			<li>Programming logic</li>
			<li>Algorithmic thinking</li>
			<li>Working in the terminal</li>
			<li>Testing Python code</li>
			<li>Data types</li>
			<li>Variables</li>
			<li>Comparison operators</li>
			<li>Conditionals, logical operators</li>
			<li>Lists, list methods and comprehensions</li>
			<li>Strings, string methods and comprehensions</li>
			<li>Loops</li>
			<li>
			Dictionaries, tuples, sets, their methods and
			comprehensions{" "}
			</li>
			<li>Functions and functional programming </li>
			<li>Built-in functions</li>
			<li>Lambdas</li>
			<li>Debugging, try and catch</li>
			<li>Using external APIs</li>
			<li>More than 100 exercises</li>
			</ul>
			</div>
			</article>




			<article className='flex450'>
			<div className="video-container offsetImage">
			<iframe
			width="560"
			height="315"
			src="https://www.youtube.com/embed/eji-kVu-Tas?rel=0&modestbranding=1"
			frameBorder="0"
			allowFullScreen
			></iframe>
			</div>
			</article>


			<article className="transparentContainer flex450">
			<h2>How will you learn that</h2>
			<div className='whiteCard'>
			<p>
			After buying the course you will get the list of recommended
			pre-course materials and the main materials for this course,
			including:
			</p>
			<ul>
			<li>
			Text materials explaining every topic with code examples
			</li>
			<li>Accompanying video lessons for every topic</li>
			<li>More than 100 coding exercises to solve divided by topic</li>
			<li>Automatic test for each exercise to check if your code works</li>
			</ul>
			</div>
			</article>




			<article className="transparentContainer flex300">
			<h2>The outcomes</h2>
			<div className='whiteCard'>
			<ul>
			<li>
			After this course you will have a strong understanding
			of the <strong>programming logic</strong>,{" "}
			<strong>syntax</strong> and <strong>usage</strong> of
			Python.{" "}
			</li>
			<li>
			You will be ready to move on and learn how to use
			modules and libraries to start work with data or learn
			how to build servers with Python.
			</li>
			</ul>
			</div>
			</article>

			<article className="transparentContainer flex300">
			<h2>If you will need help</h2>
			<div className='whiteCard'>
			<p>The course is prepared to be taken as a self-learning as it contains all the necessary information to do so, including video lessons and tests for each exercise.</p> 
			<p>In case you would like to get help fomr a mentor <span className='highlighter'>you will be able to book a 1:1 session at any time for an extra price</span>.</p>
			</div>
			</article>

			<article className="transparentContainer flex600 flexContent">
			<h2>Access</h2>
			
			<div className="whiteCard bottomSpace flex600">
			<span>
			<h4>Buy the course and start learning today!</h4>
			<Instalments/>
			<h2>Special price: 299€</h2>
			{/*<<p>{courses[0].shortdesc}</p>
			</span>
			<span>
			<p>{courses[0].fulldesc1}</p>
			<p>{courses[0].fulldesc2}</p>*/}
			<Link
			to="/register"
			state={{
				course: 'Programming with Python self-learning course',
				date: 'Today!',
				cost: 299,
				images: [pythonImage],
			}}
			>
			<button style={{ marginBottom: "1em" }}>Buy this course</button>
			</Link>
			<p className="finePrint">
			By signing up you agree to the{" "}
			<a href="/about-us/terms-and-conditions/">
			Terms and Conditions
			</a>{" "}
			stated on the website.
			</p>
			</span>
			</div>
			</article>


			<article className="transparentContainer flex300">
			<h2>Prerequisites</h2>
			<div className='whiteCard'>
			<ul>
			<li>
			You will need a computer with the access to internet,
			the OS doesn't matter although we recommend MacOS as the
			most suitable and fast; Windows and Linux are also fine
			</li>
			<li>
			Any code editor: Sublime Text, Visual Studio Code, Atom,
			etc...
			</li>
			<li>A free <a href='https://github.com'>GitHub</a> account</li>
			{/*<li>
			If you can install Git it would be amazing.
			(Instructions for{" "}
				<a href="https://git-scm.com/book/en/v2/Getting-Started-Installing-Git">
				Mac
				</a>
				, <a href="https://gitforwindows.org/">Windows</a>,{" "}
				<a href="https://git-scm.com/book/en/v2/Getting-Started-Installing-Git">
				Linux
				</a>
				)
			</li>*/}
			</ul>
			</div>
			</article>

			<article className='transparentContainer flex300'>
			<h2>Who is this course for?</h2>
			<div className='whiteCard'>
			<ul>
			<li>
			Anybody who wants to learn programming, Python is a
			great way to start!
			</li>
			<li>
			If you want to learn data science or data analytics,
			Python is your choice
			</li>
			<li>
			Developers with some experience in other languages, it
			will be a quick and guided way to grasp Python
			fundamentals
			</li>
			</ul>
			</div>
			</article>


			<article className='flex450'>
			<Contacts
			title={"Inquiry about the course"}
			subject={"Inquiry about Python course"}
			/>
			</article>
			</div>
			)
}

export default PythonSelfCourse




/*  ONLINE MENTORED ONE IS BELOW

DB data:
Title
Programming with Python
Id
605cb64eb80bf3c9e49ded71
Fullprice
1200
Priceoptions
Signupprice
1200
Fulldesc 1
4 weeks, online, mentored, flexible schedule
Fulldesc 2
Tuition: 1200€
Shortdesc
Online mentored course for beginners
Startdates
[1]
October 11, 2021
Starttime
Durationdays
Durationweeks
4
Image
https://barcelonacodeschool.com/static/programming_with_python-5a974b25424e306b05344992285b9ca5.png
Tag
python

=== END DB DATA

import React, { useState, useEffect } from "react"
import axios from "axios"
import { Link } from "gatsby"
import Helmet from "react-helmet"

import ReqCur from "../components/reqCur"

import { StripeProvider } from "react-stripe-elements"
import { Stripe } from "stripe.js"
import Checkout from "../components/checkout"

import Contacts from "../components/contactform"
// import Covid from '../components/covid';

import css from "../../pics/programming_with_python.png"

const PythonCourse = () => {
	let [courses, setCourses] = useState([])

	useEffect(() => {
		let getData = async () => {
			let data = await axios.get(
				"https://barcelonacodeschool.com/events/python"
				)
			// filling up starting dates for the recurring course
			var today = new Date()
			var future = new Date()
			var end = new Date(future.setMonth(future.getMonth() + 10))
			for (
				var i = new Date(data.data[0].startdates[0]);
				i < end;
				i.setDate(i.getDate() + 28)
				) {
				data.data[0].startdates.push(
					`${i.toLocaleString("default", {
						month: "long",
					})} ${i.getDate()}, ${i.getFullYear()}`
					)
		}
		// Christmas break splicing
		data.data[0].startdates.splice(data.data[0].startdates.indexOf("December 20, 2021"),1)
		data.data[0].startdates.splice(data.data[0].startdates.indexOf("December 6, 2021"),1)
		data.data[0].startdates.splice(data.data[0].startdates.indexOf("November 22, 2021"),1)
		// console.log("DATES", data.data[0].startdates)
		data.data[0].startdates = data.data[0].startdates.filter(
			(d) => new Date(d) > today
			)

		setCourses(data.data)
		// console.log('data.data ', data.data)
	}
	getData()
}, [])

	let renderCourses = () => {
		// console.log(courses)
		return courses[0].startdates.map((date, i) => (
			<div key={i} className="whiteCard bottomSpace flex300">
			<span>
			<h4>{date}</h4>
			<h2>{courses[0].title}</h2>
			<p>{courses[0].shortdesc}</p>
			</span>
			<span>
			<p>{courses[0].fulldesc1}</p>
			<p>{courses[0].fulldesc2}</p>
			<Link
			to="/register"
			state={{
				course: courses[0].title,
				date: date,
				cost: courses[0].signupprice,
				images: [courses[0].image],
			}}
			>
			<button style={{ marginBottom: "1em" }}>Sign up</button>
			</Link>
			<p className="finePrint">
			By signing up you agree to the{" "}
			<a href="/about-us/terms-and-conditions/">
			Terms and Conditions
			</a>{" "}
			stated on the website.
			</p>
			</span>
			</div>
			))
	}

	return (
		<div className="flexContent">
		<Helmet
		title="Programming with Python"
		meta={[
			{
				name: "description",
				content:
				"Programming with Python -- online mentored course designed for complete beginners to introduce them to programming and teach Python. Mentored online Python course with flexible schedule and support from live instructors.",
			},
			{
				name: "keywords",
				content:
				"programming with python, python online course, learn python online",
			},
			{
				name: "viewport",
				content: "width=device-width, initial-scale=1",
			},
			{
				property: "og:title",
				content: "Programming with Python course",
			},
			{
				property: "og:description",
				content:
				"Programming with Python -- online mentored course designed for complete beginners to introduce them to programming and teach Python. Mentored online Python course with flexible schedule and support from live instructors.",
			},
			{
				property: "og:image",
				content:
				"https://barcelonacodeschool.com/files/pics/programming_with_python.png",
			},
			{
				property: "og:url",
				content:
				"https://barcelonacodeschool.com/programming-with-python/",
			},
			{ name: "twitter:card", content: "summary_large_image" },
			]}
			script={[{ src: "https://js.stripe.com/v3/" }]}
			/>
			<h1 className='transparentContainer flex900'>Programming with Python</h1>


			<img
			style={{ width: "100%" }}
			className="banners flex900"
			src={css}
			alt="Programming with Python -- Online mentored course for beginners"
			/>

			<h2 className='transparentContainer flex450'>Online mentored course for beginners</h2>





			<div className='socialsharebuttons transparentContainer'>
			<div className='socialshare'>
			<a className="twitter-share-button "
			href="https://twitter.com/intent/tweet?text='Programming with Python in Barcelona Code School'&url=https://barcelonacodeschool.com/programming-with-python/"
			data-size="large">
			<button>tweet</button>
			</a>
			</div>

			<div className="fb-share-button socialshare" data-href="https://developers.facebook.com/docs/plugins/" data-layout="button" data-size="small">
			<a target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=https://barcelonacodeschool.com/programming-with-python/" className="fb-xfbml-parse-ignore">
			<button>facebook</button>
			</a>
			</div>
			</div>






			<h2 className="transparentContainer flex300">
			<span className="highlighter">4 weeks</span> of intensive guided studies</h2>
			<h2 className="transparentContainer flex225">Part-time</h2>


			<article className="transparentContainer flex450">
			<h2>Overview</h2>
			<div className='whiteCard'>
			<ul>
			<li>
			7 hours of{" "}
			<span className="highlighter">video lessons</span> in total
			</li>
			<li>
			More than 100{" "}
			<span className="highlighter">coding challenges</span> to
			solve
			</li>
			<li>
			3 bigger command line{" "}
			<span className="highlighter">projects</span>
			</li>
			<li>
			<span className="highlighter">4 1:1 sessions</span> with
			your mentor
			</li>
			<li>
			<span className="highlighter">Support platform</span> for
			coding-related questions
			</li>
			<li>
			<span className="highlighter">Chat</span> channel to talk to
			other students and mentors
			</li>
			<li>
			<span className="highlighter">Flexible</span> daily schedule
			</li>
			<li>
			Suitable for complete{" "}
			<span className="highlighter">beginners</span>
			</li>
			<li>
			Estimated time commitment —{" "}
			<span className="highlighter">20-40 hours per week</span>
			</li>
			<li>
			<span className="highlighter">Pre-course</span> materials ~
			20-40 hours
			</li>
			</ul>
			</div>
			</article>

			<article className="transparentContainer flex450">
			<h2>What you'll learn</h2>
			<div className='whiteCard'>
			<ul>
			<li>Programming logic</li>
			<li>Algorithmic thinking</li>
			<li>Working in the terminal</li>
			<li>Testing Python code</li>
			<li>Data types</li>
			<li>Variables</li>
			<li>Comparison operators</li>
			<li>Conditionals, logical operators</li>
			<li>Lists, list methods and comprehensions</li>
			<li>Strings, string methods and comprehensions</li>
			<li>Loops</li>
			<li>
			Dictionaries, tuples, sets, their methods and
			comprehensions{" "}
			</li>
			<li>Functions and functional programming </li>
			<li>Built-in functions</li>
			<li>Lambdas</li>
			<li>Debugging, try and catch</li>
			<li>Using external APIs</li>
			<li>More than 100 exercises</li>
			<li>3 projects! </li>
			</ul>
			</div>
			</article>




			<article className='flex450'>
			<div className="video-container">
			<iframe
			width="560"
			height="315"
			src="https://www.youtube.com/embed/eji-kVu-Tas?rel=0&modestbranding=1"
			frameBorder="0"
			allowFullScreen
			></iframe>
			</div>
			</article>


			<article className="transparentContainer flex450">
			<h2>How will you learn that</h2>
			<div className='whiteCard'>
			<p>
			After signing up online you will get the list of recommended
			pre-course materials and the main materials for this course,
			including:
			</p>
			<ul>
			<li>
			Text materials explaining every topic with code examples
			</li>
			<li>Accompanying video lessons for every topic</li>
			<li>
			Access to the support platform to post your questions
			(and get reply in 24 hours but usually same day) or
			browse through the questions from the previous students
			</li>
			<li>
			4 1:1 sessions with your mentor to book from our
			calendar
			</li>
			<li>
			Access to Slack channel to chat with us and fellow
			students
			</li>
			<li className="highlighter">
			During the course we are going to keep in touch
			constantly tracking your progress, checking the code and
			giving feedback. You will be guided through this
			learning process and working closely with our mentors.{" "}
			</li>
			</ul>
			</div>
			</article>




			<article className="transparentContainer flex300">
			<h2>The outcomes</h2>
			<div className='whiteCard'>
			<ul>
			<li>
			After this course you will have a strong understanding
			of the <strong>programming logic</strong>,{" "}
			<strong>syntax</strong> and <strong>usage</strong> of
			Python.{" "}
			</li>
			<li>
			You will be ready to move on and learn how to use
			modules and libraries to start work with data or learn
			how to build servers with Python.
			</li>
			</ul>
			</div>
			</article>

			<article className='transparentContainer flex225'>
			<h2>Tuition</h2>
			<div className='whiteCard'>
			<h2>1200€</h2>
			<p>Sign up online and reserve your seat.</p>
			</div>
			</article>

			<article className="transparentContainer flex300">
			<h2>Preparing</h2>
			<div className='whiteCard'>
			<p>
			After signing up you will receive the list of the materials
			we recommend to go through to prepare for the course. Please
			expect to spend around 40-80 hours on that.
			</p>
			</div>
			</article>

			<article className="transparentContainer flex900 flexContent">
			<h2>Access</h2>
			<div className='flexContent'>
			{courses.length > 0 && renderCourses()}
			</div>
			</article>

			<article className="transparentContainer flex300">
			<h2>Prerequisites</h2>
			<div className='whiteCard'>
			<ul>
			<li>
			You will need a computer with the access to internet,
			the OS doesn't matter although we recommend MacOS as the
			most suitable and fast; Windows and Linux are also fine
			</li>
			<li>
			Any code editor: Sublime Text, Visual Studio Code, Atom,
			etc...
			</li>
			<li>An account with GitLab</li>
			<li>
			If you can install Git it would be amazing.
			(Instructions for{" "}
				<a href="https://git-scm.com/book/en/v2/Getting-Started-Installing-Git">
				Mac
				</a>
				, <a href="https://gitforwindows.org/">Windows</a>,{" "}
				<a href="https://git-scm.com/book/en/v2/Getting-Started-Installing-Git">
				Linux
				</a>
				)
			</li>
			</ul>
			</div>
			</article>

			<article className='transparentContainer flex300'>
			<h2>Who is this course for?</h2>
			<div className='whiteCard'>
			<ul>
			<li>
			Anybody who wants to learn programming, Python is a
			great way to start!
			</li>
			<li>
			If you want to learn data science or data analytics,
			Python is your choice
			</li>
			<li>
			Developers with some experience in other languages, it
			will be a quick and guided way to grasp Python
			fundamentals
			</li>
			</ul>
			</div>
			</article>


			<article className='flex450'>
			<Contacts
			title={"Inquiry about the course"}
			subject={"Inquiry about Python course"}
			/>
			</article>
			</div>
			)
}

export default PythonCourse

*/
